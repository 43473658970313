import React, { useReducer } from "react";
import { createContext, useContext } from "react";
import { v4 } from "uuid";
import Notification from "./Notification";

const NotificationContext = createContext(null);

const NotificationProvider = (props: any) => {
  const [state, dispatch] = useReducer(
    (state: any, action: any) => {
      switch (action.type) {
        case "ADD_NOTIFICATION":
          return [...state, { ...action.payload }];
        case "REMOVE_NOTIFICATION":
          return state.filter((el: any) => el.id !== action.id);
        default:
          return state;
      }
    },
    [
      //   {
      //     id: v4(),
      //     type: "SUCCESS",
      //     message: "Hello World",
      //   },
    ]
  );

  return (
    <NotificationContext.Provider value={dispatch}>
      <div
        className={" fixed top-0 right-0 mt-4 mr-4 h-16 z-75 w-full lg:w-1/4"}
      >
        {state.map((note: any) => {
          return <Notification dispatch={dispatch} key={note.id} {...note} />;
        })}
      </div>
      {props.children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  const dispatch = useContext(NotificationContext);
  return (props: any) => {
    dispatch({
      type: "ADD_NOTIFICATION",
      payload: {
        id: v4(),
        ...props,
      },
    });
  };
};

export default NotificationProvider;





export const withHooksHOC = (Component : any) => {
  return(props:any) => {
    const dispatch = useNotification();
    const handleError= (type : string, title : string, message : string, ) => {
      dispatch({
          type: type.toUpperCase(),
          title: title,
          message: message

          }
      )
  }



    return <Component ErrorNote={handleError} {...props} />;
  }


}
