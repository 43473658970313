import RegisterPage from "./register/RegisterPage";
import {
  Switch,
  Route,
  useHistory,
  useLocation,
  Redirect,
  BrowserRouter as Router,
} from "react-router-dom";
import {
  ThemeProvider,
  StyledEngineProvider,
  PaletteColorOptions,
} from "@mui/material/styles";
import ScrollToTop from "./utils/ScrollReset";
import AppView from "./views/AppView";
import LoginPage from "./login/LoginPage";
import PrivateRoute from "./views/privateRoute";
import ClientInvoice from "./client-facing/ClientInvoice";
import ManageUsers from "./utils/manageUsers";
import { useContext } from "react";
import store from "./redux/store";
import ConsolidatedClientInvoice from "./client-facing/ConsolidatedClientInvoice";
import ExtensionForm from "./components/Extensions/ExtensionForm";
import { createTheme } from "@mui/material";
import Header from "./components/Units/InfoSheetComponents/Header";
import Body from "./components/Units/InfoSheetComponents/Body";
import CheckOutForm from "./components/Bookings/CheckOut/CheckOutForm";
import Maintenance from "./components/Maintenance";
// import componentDidMount from "./components/Units/UnitProfile";
// import ViewSheet from "./components/Units/InfoSheetComponents/ViewSheet";
declare module "@mui/material/styles" {
  interface CustomPalette {
    forestGreen: PaletteColorOptions;
  }
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    forestGreen: true;
  }
}

declare module "@mui/material/Checkbox" {
  interface CheckboxPropsColorOverrides {
    forestGreen: true;
  }
}

declare module "@mui/material/Checkbox" {
  interface CheckboxPropsColorOverrides {
    forestGreen: true;
  }
}
const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor: any) =>
  augmentColor({ color: { main: mainColor } });
const theme = createTheme({
  palette: {
    forestGreen: createColor("#03937F"),
  },
});

function App(this: any) {
  // find user from aws?
  let user = "user";
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <div className="w-full ">
          <Switch>
            <ScrollToTop>
              {/* Change appview route to <PrivateRoute path={"/" + user}> once Auth is ready */}
              {/* See link for details https://reactrouter.com/web/example/auth-workflow */}

              {/* <Route exact path="/register">
                <RegisterPage />
              </Route> */}

              {/*IF UNDERGOING MAINTENANCE, UNCOMMENT BELOW AND COMMENT OUT THE REST */}
              <Route path={"/*"}>
                <Maintenance/>
              </Route>
{/* 
              <PrivateRoute path={"/" + user}>
                <AppView UserName={user} />
              </PrivateRoute>

              <PrivateRoute path={"/client-invoice/"}>
                <ClientInvoice />
              </PrivateRoute>

              <PrivateRoute path={"/extension/requestform/:bookingId"}>
                <ExtensionForm />
              </PrivateRoute>

              <PrivateRoute path={"/bookings/checkout/getpublic/:bookingId"}>
                <CheckOutForm />
              </PrivateRoute>

              <PrivateRoute path={"/unit/getpublic/:unitId"}>
                <Header />
                <Body />
              </PrivateRoute>

              <PrivateRoute path={"/Consolidated-Client-Invoice/"}>
                <ConsolidatedClientInvoice/>
              </PrivateRoute>

              <Route exact path="/manage-users">
                <ManageUsers />
              </Route>

              <Route exact path="/">
                <LoginPage />
              </Route>

              <Route exact path="/">
                <LoginPage />
              </Route> */}
            </ScrollToTop>
          </Switch>
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
