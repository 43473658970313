export enum UserTypes {
  Finance = "Finance",
  Ops = "Ops",
  Sales = "Sales",
  Cleaning = "Cleaning",
  Office = "Office",
  Admin = "Admin",
}

export type State = {
  userType: UserTypes;
};

const initialState: State = {
  userType: UserTypes.Admin,
};

type Action = { type: "changeUserType"; newUserType: UserTypes };

export const userReducer = (state = initialState, action: Action): State => {
  switch (action.type) {
    case "changeUserType":
      state.userType = action.newUserType;
      return state;
    default:
      return state;
  }
};

export default userReducer;
