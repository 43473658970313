import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";

// reducers
import reducer from "./loginReducer";

import { registerReducer } from "./registerReducer";
import { authReducer } from "./authReducer";
import { unitOwnerReducer } from "./unitOwnerReducer";
import { unitReducer } from "./unitReducer";
import { bookingReducer } from "./bookingReducer";
import { invoiceReducer } from "./invoiceReducer";
import { corpContactReducer } from "./corpContactReducer";
import { buildingReducer } from "./buildingReducer";
import { tenantReducer } from "./tenantReducer";
import { fileReducer } from "./fileReducer";
import {blackoutReducer} from "./blackoutReducer"; 
import {userReducer} from "./userReducer"; 
import { checkInReducer } from "./checkInReducer";
import {consolidatedInvoiceReducer} from "./consolidatedReducer";
import { cleaningReducer } from "./cleaningReducer"; 
import { financeReducer } from "./financeReducer";

const rootReducer = combineReducers({
  reducer,
  registerReducer,
  authReducer,
  unitOwnerReducer,
  unitReducer,
  bookingReducer,
  invoiceReducer,
  consolidatedInvoiceReducer,
  corpContactReducer,
  buildingReducer,
  tenantReducer,
  fileReducer,
  blackoutReducer, 
  userReducer,
  checkInReducer,
  cleaningReducer,
  financeReducer,
});

// const store = createStore(rootReducer, {}, applyMiddleware(thunk, logger));
const store = createStore(rootReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

export default store;
