import React, { useEffect, useState } from "react";
import { createTheme, Divider, PaletteColorOptions, TextField } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { IoIosConstruct } from "react-icons/io";


declare module "@mui/material/styles" {
  interface CustomPalette {
    forestGreen: PaletteColorOptions;
  }
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}
}
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    forestGreen: true;
  }
}
declare module "@mui/material/Checkbox" {
  interface CheckboxPropsColorOverrides {
    forestGreen: true;
  }
}
declare module "@mui/material/TextField" {
  interface TextFieldPropsColorOverrides {
    forestGreen: true;
  }
}
const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor: any) =>
  augmentColor({ color: { main: mainColor } });
const theme = createTheme({
  palette: {
    forestGreen: createColor("#03937F"),
  },
});

const Maintenance = (props: any) => {

  useEffect(() => {
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div className="grid h-screen place-items-center">
          <div className="grid text-sm font-bold h-3/6 w-3/6 lg:px-5 py-2 rounded-md shadow-md place-items-center">
              <div className="grid place-items-center">
                <IoIosConstruct className="text-7xl mb-3 text-red-500"/>
                <h1 className="h1">System Maintenance</h1>
                <span className="mt-2">Sorry, we're down for maintenance!</span>
                <span className="">Feel free to contact MK or Newman if there are any emergencies regarding the system</span>
                <span>Email: <span className="text-green-500"><strong>matthew@skvyiewsuites.com</strong></span> | Email: <span className="text-green-500"><strong>newman@skvyiewsuites</strong></span></span>
                <span className="my-3"> - Dev Team</span>
              </div>
          </div>
      </div>
    </ThemeProvider>
  );
};

export default Maintenance;
