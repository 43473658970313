import React, { useState, useEffect } from "react";
import { CgClose } from "react-icons/cg";
import { RiAlertFill } from "react-icons/ri";
import { IoMdAlert } from "react-icons/io";
import { GoCheck } from "react-icons/go";
import { BsInfoCircleFill } from "react-icons/bs";

//if you want visual cue for how long the notification will stay, change time to width of a div on the bottom of the note

const Notification = (props: any) => {
  const [time, setTime] = useState(0);
  const [exit, setExit] = useState(false);
  const [intervalId, setIntervalID] = useState(null);

  const handleStartTimer = () => {
    const id = setInterval(() => {
      setTime((prev) => {
        if (prev < 100) {
          return prev + 0.5;
        }
        clearInterval(id);
        return prev;
      });
    }, 20);
    setIntervalID(id);
  };

  const handlePauseTimer = () => {
    clearInterval(intervalId);
  };

  const handleCloseNotification = () => {
    handlePauseTimer();
    setExit(true);
    setTimeout(() => {
      props.dispatch({
        type: "REMOVE_NOTIFICATION",
        id: props.id,
      });
    }, 400);
  };

  React.useEffect(() => {
    if (time === 100) {
      handleCloseNotification();
    }
  }, [time]);

  React.useEffect(() => {
    handleStartTimer();
  }, []);

  let typeStyle = "";
  let exitClass = " ";
  let noteIcon = <></>;

  if (props.type === "SUCCESS") {
    typeStyle = " border-l-4 border-green-500";
    exitClass = " SlideLeft ";
    noteIcon = <GoCheck className="text-green-500 text-2xl" />;
  }

  if (props.type === "ERROR") {
    typeStyle = " border-l-4   border-red-400 ";
    exitClass = " errorAni ";
    noteIcon = <RiAlertFill className="text-red-400 text-2xl" />;
  }

  if (props.type === "WARNING") {
    typeStyle = " border-l-4   border-yellow-500 ";
    exitClass = " SlideLeft ";
    noteIcon = <IoMdAlert className="text-yellow-500 text-2xl " />;
  }

  if (props.type === "INFO") {
    typeStyle = " border-l-4   border-blue-500 ";
    exitClass = " SlideLeft ";
    noteIcon = <BsInfoCircleFill className="text-blue-500 text-2xl" />;
  }

  if (exit === true) {
    exitClass = " SlideRight ";
  }

  return (
    <div
      onMouseEnter={handlePauseTimer}
      onMouseLeave={handleStartTimer}
      className={
        "  font-europe w-full text-gray-700 shadow-lg rounded-md bg-gray-100 overflow-hidden mb-4 flex flex-row justify-between " +
        typeStyle +
        exitClass
      }
    >
      <div className="flex flex-row justify-center items-center ml-3">
        {noteIcon}
        <div className="ml-2 py-4 px-2 flex flex-col  ">
        <h1 className="text-base text-gray-800 font-bold">{props.title}</h1>
        <p style={{maxWidth : "22rem"}} className="text-sm text-gray-500  ">{props.message}</p>

        </div>
      </div>
      <button className="mr-3" onClick={handleCloseNotification}>
        <CgClose className="text-base hover:opacity-50" />
      </button>
    </div>
  );
};

export default Notification;
