export type State = {
  unitOwners: any[];
  curEmail: string;
  curId: number;
  listUpdate: any;
};

const initialState: State = {
  unitOwners: [],
  curEmail: "",
  curId: -1,
  listUpdate: undefined,
};

type Action =
  | { type: "addOwner"; newOwner: { id: number; email: string } }
  | { type: "removeOwner"; id: number }
  | { type: "ownerCurEmail"; email: string }
  | { type: "ownerIdTransfer"; id: number }
  | { type: "ownerListUpdate"; listUpdate: any };

export const unitOwnerReducer = (
  state = initialState,
  action: Action
): State => {
  switch (action.type) {
    case "addOwner":
      state.unitOwners.push(action.newOwner);
      state.unitOwners.forEach((item, index) => {
        item.id = index + 1;
      });
      return state;
    case "removeOwner":
      let ind = state.unitOwners.findIndex((o) => o.id === action.id);
      state.unitOwners.splice(ind, 1);
      state.unitOwners.forEach((item, index) => {
        item.id = index + 1;
      });
      return state;
    case "ownerCurEmail":
      state.curEmail = action.email;
      return state;
    case "ownerIdTransfer":
      state.curId = action.id;
      return state;
    case "ownerListUpdate":
      state.listUpdate = action.listUpdate;
      return state;
    default:
      return state;
  }
};

export default unitOwnerReducer;
